import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import './assets/forms.css'
import './assets/dashboard.css'
import './assets/template.css'
import './assets/slider.css'
import './assets/home.css'

import VueFormulate from '@braid/vue-formulate';

Vue.use(VueFormulate);
Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
