<template>
        <section class="cooprate-culture-contact" id="contact">
        <div class="cooprate-culture-contact-container cooprate-culture-container">
            <div class="contact-headline">
                <h1>Contact Us</h1>
                <p>Complete the form below, call our hotline, search our directory or visit our  office.</p>
            </div>
     <div class="contact-form">
                <form action="">
                    <div class="names">
                        <div class="control-group name">
                            <label for="name">First Name</label><br>
                            <input type="text" placeholder="John">
                        </div>
                        <div class="control-group name">
                            <label for="name">Last Name</label><br>
                            <input type="text" name="email" placeholder="Kamana">
                        </div>
                    </div>
                    <div class="names">
                        <div class="control-group name">
                            <label for="name">Company Name</label><br>
                            <input type="text" placeholder="Company">
                        </div>
                        <div class="control-group name">
                            <label for="name">Business Email</label><br>
                            <input type="email" name="email" placeholder="example@gmail.com">
                        </div>
                    </div>
                    <div class="control-group-two">
                        <label for="email">Region</label><br>
                        <select name="country" class="field-size-top-large contact-select touched dirty is-valid w-full" id="contact-country" required="" data-form-field-select="">
                            <option value="" disabled="" selected="">Select Region</option>
                                <option value="United States" data-related-select="">United States</option>
                                <option value="United Kingdom" data-related-select="">United Kingdom</option>
                                <option value="Afghanistan" data-related-select="">Afghanistan</option>
                                <option value="Aland Islands" data-related-select="">Aland Islands</option>
                                <option value="Albania" data-related-select="">Albania</option>
                                <option value="Algeria" data-related-select="">Algeria</option>
                                <option value="Andorra" data-related-select="">Andorra</option>
                                <option value="Angola" data-related-select="">Angola</option>
                                <option value="Anguilla" data-related-select="">Anguilla</option>
                                <option value="Antarctica" data-related-select="">Antarctica</option>
                                <option value="Antigua and Barbuda" data-related-select="">Antigua and Barbuda</option>
                                <option value="Argentina" data-related-select="">Argentina</option>
                                <option value="Armenia" data-related-select="">Armenia</option>
                                <option value="Aruba" data-related-select="">Aruba</option>
                                <option value="Australia" data-related-select="">Australia</option>
                                <option value="Austria" data-related-select="">Austria</option>
                                <option value="Azerbaijan" data-related-select="">Azerbaijan</option>
                                <option value="Bahamas" data-related-select="">Bahamas</option>
                                <option value="Bahrain" data-related-select="">Bahrain</option>
                                <option value="Bangladesh" data-related-select="">Bangladesh</option>
                                <option value="Barbados" data-related-select="">Barbados</option>
                                <option value="Belarus" data-related-select="">Belarus</option>
                                <option value="Belgium" data-related-select="">Belgium</option>
                                <option value="Belize" data-related-select="">Belize</option>
                                <option value="Benin" data-related-select="">Benin</option>
                                <option value="Bermuda" data-related-select="">Bermuda</option>
                                <option value="Bhutan" data-related-select="">Bhutan</option>
                                <option value="Bolivia, Plurinational State of" data-related-select="">Bolivia, Plurinational State of</option>
                                <option value="Bonaire, Sint Eustatius and Saba" data-related-select="">Bonaire, Sint Eustatius and Saba</option>
                                <option value="Bosnia and Herzegovina" data-related-select="">Bosnia and Herzegovina</option>
                                <option value="Botswana" data-related-select="">Botswana</option>
                                <option value="Bouvet Island" data-related-select="">Bouvet Island</option>
                                <option value="Brazil" data-related-select="">Brazil</option>
                                <option value="British Indian Ocean Territory" data-related-select="">British Indian Ocean Territory</option>
                                <option value="Brunei Darussalam" data-related-select="">Brunei Darussalam</option>
                                <option value="Bulgaria" data-related-select="">Bulgaria</option>
                                <option value="Burkina Faso" data-related-select="">Burkina Faso</option>
                                <option value="Burundi" data-related-select="">Burundi</option>
                                <option value="Cambodia" data-related-select="">Cambodia</option>
                                <option value="Cameroon" data-related-select="">Cameroon</option>
                                <option value="Canada" data-related-select="">Canada</option>
                                <option value="Cape Verde" data-related-select="">Cape Verde</option>
                                <option value="Cayman Islands" data-related-select="">Cayman Islands</option>
                                <option value="Central African Republic" data-related-select="">Central African Republic</option>
                                <option value="Chad" data-related-select="">Chad</option>
                                <option value="Chile" data-related-select="">Chile</option>
                                <option value="China" data-related-select="">China</option>
                                <option value="Christmas Island" data-related-select="">Christmas Island</option>
                                <option value="Cocos (Keeling) Islands" data-related-select="">Cocos (Keeling) Islands</option>
                                <option value="Colombia" data-related-select="">Colombia</option>
                                <option value="Comoros" data-related-select="">Comoros</option>
                                <option value="Congo, the Democratic Republic of the" data-related-select="">Congo, the Democratic Republic of the</option>
                                <option value="Congo" data-related-select="">Congo</option>
                                <option value="Cook Islands" data-related-select="">Cook Islands</option>
                                <option value="Costa Rica" data-related-select="">Costa Rica</option>
                                <option value="Cote d'Ivoire" data-related-select="">Cote d'Ivoire</option>
                                <option value="Croatia" data-related-select="">Croatia</option>
                                <option value="Cuba" data-related-select="">Cuba</option>
                                <option value="Curaçao" data-related-select="">Curaçao</option>
                                <option value="Cyprus" data-related-select="">Cyprus</option>
                                <option value="Czech Republic" data-related-select="">Czech Republic</option>
                                <option value="Denmark" data-related-select="">Denmark</option>
                                <option value="Djibouti" data-related-select="">Djibouti</option>
                                <option value="Dominica" data-related-select="">Dominica</option>
                                <option value="Dominican Republic" data-related-select="">Dominican Republic</option>
                                <option value="Ecuador" data-related-select="">Ecuador</option>
                                <option value="Egypt" data-related-select="">Egypt</option>
                                <option value="El Salvador" data-related-select="">El Salvador</option>
                                <option value="Equatorial Guinea" data-related-select="">Equatorial Guinea</option>
                                <option value="Eritrea" data-related-select="">Eritrea</option>
                                <option value="Estonia" data-related-select="">Estonia</option>
                                <option value="Ethiopia" data-related-select="">Ethiopia</option>
                                <option value="Falkland Islands (Malvinas)" data-related-select="">Falkland Islands (Malvinas)</option>
                                <option value="Faroe Islands" data-related-select="">Faroe Islands</option>
                                <option value="Fiji" data-related-select="">Fiji</option>
                                <option value="Finland" data-related-select="">Finland</option>
                                <option value="France" data-related-select="">France</option>
                                <option value="French Guiana" data-related-select="">French Guiana</option>
                                <option value="French Polynesia" data-related-select="">French Polynesia</option>
                                <option value="French Southern Territories" data-related-select="">French Southern Territories</option>
                                <option value="Gabon" data-related-select="">Gabon</option>
                                <option value="Gambia" data-related-select="">Gambia</option>
                                <option value="Georgia" data-related-select="">Georgia</option>
                                <option value="Germany" data-related-select="">Germany</option>
                                <option value="Ghana" data-related-select="">Ghana</option>
                                <option value="Gibraltar" data-related-select="">Gibraltar</option>
                                <option value="Greece" data-related-select="">Greece</option>
                                <option value="Greenland" data-related-select="">Greenland</option>
                                <option value="Grenada" data-related-select="">Grenada</option>
                                <option value="Guadeloupe" data-related-select="">Guadeloupe</option>
                                <option value="Guam" data-related-select="">Guam</option>
                                <option value="Guatemala" data-related-select="">Guatemala</option>
                                <option value="Guernsey" data-related-select="">Guernsey</option>
                                <option value="Guinea-Bissau" data-related-select="">Guinea-Bissau</option>
                                <option value="Guinea" data-related-select="">Guinea</option>
                                <option value="Guyana" data-related-select="">Guyana</option>
                                <option value="Haiti" data-related-select="">Haiti</option>
                                <option value="Heard Island and McDonald Islands" data-related-select="">Heard Island and McDonald Islands</option>
                                <option value="Holy See (Vatican City State)" data-related-select="">Holy See (Vatican City State)</option>
                                <option value="Honduras" data-related-select="">Honduras</option>
                                <option value="Hong Kong" data-related-select="">Hong Kong</option>
                                <option value="Hungary" data-related-select="">Hungary</option>
                                <option value="Iceland" data-related-select="">Iceland</option>
                                <option value="India" data-related-select="">India</option>
                                <option value="Indonesia" data-related-select="">Indonesia</option>
                                <option value="Iran, Islamic Republic of" data-related-select="">Iran, Islamic Republic of</option>
                                <option value="Iraq" data-related-select="">Iraq</option>
                                <option value="Ireland" data-related-select="">Ireland</option>
                                <option value="Isle of Man" data-related-select="">Isle of Man</option>
                                <option value="Israel" data-related-select="">Israel</option>
                                <option value="Italy" data-related-select="">Italy</option>
                                <option value="Jamaica" data-related-select="">Jamaica</option>
                                <option value="Japan" data-related-select="">Japan</option>
                                <option value="Jersey" data-related-select="">Jersey</option>
                                <option value="Jordan" data-related-select="">Jordan</option>
                                <option value="Kazakhstan" data-related-select="">Kazakhstan</option>
                                <option value="Kenya" data-related-select="">Kenya</option>
                                <option value="Kiribati" data-related-select="">Kiribati</option>
                                <option value="Kuwait" data-related-select="">Kuwait</option>
                                <option value="Kyrgyzstan" data-related-select="">Kyrgyzstan</option>
                                <option value="Lao People's Democratic Republic" data-related-select="">Lao People's Democratic Republic</option>
                                <option value="Latvia" data-related-select="">Latvia</option>
                                <option value="Lebanon" data-related-select="">Lebanon</option>
                                <option value="Lesotho" data-related-select="">Lesotho</option>
                                <option value="Liberia" data-related-select="">Liberia</option>
                                <option value="Libyan Arab Jamahiriya" data-related-select="">Libyan Arab Jamahiriya</option>
                                <option value="Liechtenstein" data-related-select="">Liechtenstein</option>
                                <option value="Lithuania" data-related-select="">Lithuania</option>
                                <option value="Luxembourg" data-related-select="">Luxembourg</option>
                                <option value="Macao" data-related-select="">Macao</option>
                                <option value="Macedonia, the former Yugoslav Republic of" data-related-select="">Macedonia, the former Yugoslav Republic of</option>
                                <option value="Madagascar" data-related-select="">Madagascar</option>
                                <option value="Malawi" data-related-select="">Malawi</option>
                                <option value="Malaysia" data-related-select="">Malaysia</option>
                                <option value="Maldives" data-related-select="">Maldives</option>
                                <option value="Mali" data-related-select="">Mali</option>
                                <option value="Malta" data-related-select="">Malta</option>
                                <option value="Marshall Islands" data-related-select="">Marshall Islands</option>
                                <option value="Martinique" data-related-select="">Martinique</option>
                                <option value="Mauritania" data-related-select="">Mauritania</option>
                                <option value="Mauritius" data-related-select="">Mauritius</option>
                                <option value="Mayotte" data-related-select="">Mayotte</option>
                                <option value="Mexico" data-related-select="">Mexico</option>
                                <option value="Moldova, Republic of" data-related-select="">Moldova, Republic of</option>
                                <option value="Monaco" data-related-select="">Monaco</option>
                                <option value="Mongolia" data-related-select="">Mongolia</option>
                                <option value="Montenegro" data-related-select="">Montenegro</option>
                                <option value="Montserrat" data-related-select="">Montserrat</option>
                                <option value="Morocco" data-related-select="">Morocco</option>
                                <option value="Mozambique" data-related-select="">Mozambique</option>
                                <option value="Myanmar" data-related-select="">Myanmar</option>
                                <option value="Namibia" data-related-select="">Namibia</option>
                                <option value="Nauru" data-related-select="">Nauru</option>
                                <option value="Nepal" data-related-select="">Nepal</option>
                                <option value="Netherlands" data-related-select="">Netherlands</option>
                                <option value="Netherlands Antilles" data-related-select="">Netherlands Antilles</option>
                                <option value="New Caledonia" data-related-select="">New Caledonia</option>
                                <option value="New Zealand" data-related-select="">New Zealand</option>
                                <option value="Nicaragua" data-related-select="">Nicaragua</option>
                                <option value="Niger" data-related-select="">Niger</option>
                                <option value="Nigeria" data-related-select="">Nigeria</option>
                                <option value="Niue" data-related-select="">Niue</option>
                                <option value="Norfolk Island" data-related-select="">Norfolk Island</option>
                                <option value="North Korea" data-related-select="">North Korea</option>
                                <option value="Northern Mariana Islands" data-related-select="">Northern Mariana Islands</option>
                                <option value="Norway" data-related-select="">Norway</option>
                                <option value="Oman" data-related-select="">Oman</option>
                                <option value="Pakistan" data-related-select="">Pakistan</option>
                                <option value="Palestinian Territory, Occupied" data-related-select="">Palestinian Territory, Occupied</option>
                                <option value="Panama" data-related-select="">Panama</option>
                                <option value="Papua New Guinea" data-related-select="">Papua New Guinea</option>
                                <option value="Paraguay" data-related-select="">Paraguay</option>
                                <option value="Peru" data-related-select="">Peru</option>
                                <option value="Philippines" data-related-select="">Philippines</option>
                                <option value="Pitcairn" data-related-select="">Pitcairn</option>
                                <option value="Poland" data-related-select="">Poland</option>
                                <option value="Portugal" data-related-select="">Portugal</option>
                                <option value="Puerto Rico" data-related-select="">Puerto Rico</option>
                                <option value="Qatar" data-related-select="">Qatar</option>
                                <option value="Reunion" data-related-select="">Reunion</option>
                                <option value="Romania" data-related-select="">Romania</option>
                                <option value="Russian Federation" data-related-select="">Russian Federation</option>
                                <option value="Rwanda" data-related-select="">Rwanda</option>
                                <option value="Saint Barthélemy" data-related-select="">Saint Barthélemy</option>
                                <option value="Saint Helena, Ascension and Tristan da Cunha" data-related-select="">Saint Helena, Ascension and Tristan da Cunha</option>
                                <option value="Saint Kitts and Nevis" data-related-select="">Saint Kitts and Nevis</option>
                                <option value="Saint Lucia" data-related-select="">Saint Lucia</option>
                                <option value="Saint Martin (French part)" data-related-select="">Saint Martin (French part)</option>
                                <option value="Saint Pierre and Miquelon" data-related-select="">Saint Pierre and Miquelon</option>
                                <option value="Saint Vincent and the Grenadines" data-related-select="">Saint Vincent and the Grenadines</option>
                                <option value="Samoa" data-related-select="">Samoa</option>
                                <option value="San Marino" data-related-select="">San Marino</option>
                                <option value="Sao Tome and Principe" data-related-select="">Sao Tome and Principe</option>
                                <option value="Saudi Arabia" data-related-select="">Saudi Arabia</option>
                                <option value="Senegal" data-related-select="">Senegal</option>
                                <option value="Serbia" data-related-select="">Serbia</option>
                                <option value="Seychelles" data-related-select="">Seychelles</option>
                                <option value="Sierra Leone" data-related-select="">Sierra Leone</option>
                                <option value="Singapore" data-related-select="">Singapore</option>
                                <option value="Sint Maarten (Dutch part)" data-related-select="">Sint Maarten (Dutch part)</option>
                                <option value="Slovakia" data-related-select="">Slovakia</option>
                                <option value="Slovenia" data-related-select="">Slovenia</option>
                                <option value="Solomon Islands" data-related-select="">Solomon Islands</option>
                                <option value="Somalia" data-related-select="">Somalia</option>
                                <option value="South Africa" data-related-select="">South Africa</option>
                                <option value="South Georgia and the South Sandwich Islands" data-related-select="">South Georgia and the South Sandwich Islands</option>
                                <option value="South Korea" data-related-select="">South Korea</option>
                                <option value="South Sudan" data-related-select="">South Sudan</option>
                                <option value="Spain" data-related-select="">Spain</option>
                                <option value="Sri Lanka" data-related-select="">Sri Lanka</option>
                                <option value="Sudan" data-related-select="">Sudan</option>
                                <option value="Suriname" data-related-select="">Suriname</option>
                                <option value="Svalbard and Jan Mayen" data-related-select="">Svalbard and Jan Mayen</option>
                                <option value="Swaziland" data-related-select="">Swaziland</option>
                                <option value="Sweden" data-related-select="">Sweden</option>
                                <option value="Switzerland" data-related-select="">Switzerland</option>
                                <option value="Syrian Arab Republic" data-related-select="">Syrian Arab Republic</option>
                                <option value="Taiwan" data-related-select="">Taiwan</option>
                                <option value="Tajikistan" data-related-select="">Tajikistan</option>
                                <option value="Tanzania, United Republic of" data-related-select="">Tanzania, United Republic of</option>
                                <option value="Thailand" data-related-select="">Thailand</option>
                                <option value="Timor-Leste" data-related-select="">Timor-Leste</option>
                                <option value="Togo" data-related-select="">Togo</option>
                                <option value="Tokelau" data-related-select="">Tokelau</option>
                                <option value="Tonga" data-related-select="">Tonga</option>
                                <option value="Trinidad and Tobago" data-related-select="">Trinidad and Tobago</option>
                                <option value="Tunisia" data-related-select="">Tunisia</option>
                                <option value="Turkey" data-related-select="">Turkey</option>
                                <option value="Turkmenistan" data-related-select="">Turkmenistan</option>
                                <option value="Turks and Caicos Islands" data-related-select="">Turks and Caicos Islands</option>
                                <option value="Tuvalu" data-related-select="">Tuvalu</option>
                                <option value="Uganda" data-related-select="">Uganda</option>
                                <option value="Ukraine" data-related-select="">Ukraine</option>
                                <option value="United Arab Emirates" data-related-select="">United Arab Emirates</option>
                                <option value="United States Minor Outlying Islands" data-related-select="">United States Minor Outlying Islands</option>
                                <option value="Uruguay" data-related-select="">Uruguay</option>
                                <option value="Uzbekistan" data-related-select="">Uzbekistan</option>
                                <option value="Vanuatu" data-related-select="">Vanuatu</option>
                                <option value="Venezuela, Bolivarian Republic of" data-related-select="">Venezuela, Bolivarian Republic of</option>
                                <option value="Viet Nam" data-related-select="">Viet Nam</option>
                                <option value="Virgin Islands, British" data-related-select="">Virgin Islands, British</option>
                                <option value="Virgin Islands, U.S." data-related-select="">Virgin Islands, U.S.</option>
                                <option value="Wallis and Futuna" data-related-select="">Wallis and Futuna</option>
                                <option value="Western Sahara" data-related-select="">Western Sahara</option>
                                <option value="Yemen" data-related-select="">Yemen</option>
                                <option value="Zambia" data-related-select="">Zambia</option>
                                <option value="Zimbabwe" data-related-select="">Zimbabwe</option>
                        </select>
                    </div>
                    <div class="names">
                        <div class="control-group name">
                            <label for="name">Solutions</label><br>
                            <select name="enquiry" class="field-size-top-large contact-select touched dirty is-valid" id="contact-enquiry" required="" data-form-field-select="">
                                <option value="" disabled="" selected="">Select Solution</option>
                                    <option value="Received a Breach Notification?" data-related-select="">Received a Breach Notification?</option>
                                    <option value="Valuation" data-related-select="">Valuation</option>
                                    <option value="Business Services" data-related-select="">Business Services</option>
                                    <option value="Compliance and Regulation" data-related-select="">Compliance and Regulation</option>
                                    <option value="Corporate Finance and Restructuring" data-related-select="">Corporate Finance and Restructuring</option>
                                    <option value="Cyber Risk" data-related-select="">Cyber Risk (and Cyber Crime)</option>
                                    <option value="Digital Technology Solutions" data-related-select="">Digital Technology Solutions</option>
                                    <option value="Environmental, Social and Governance" data-related-select="">Environmental, Social and Governance</option>
                                    <option value="Investigations and Disputes" data-related-select="">Investigations and Disputes</option>
                            </select>
                        </div>
                        <div class="control-group name">
                            <label for="name">Compliance</label><br>
                            <select name="serviceneed" class="field-size-top-large contact-select touched dirty is-valid" id="contact-serviceneed" required="" data-form-field-select="">
                                <option value="">Select Compliance</option>
                                <option value="Anti-Money Laundering">Anti-Money Laundering</option>
                                <option value="Compliance">Compliance</option>
                                <option value="Background Checks / Screening">Background Checks / Screening</option>
                                <option value="Due Diligence">Due Diligence</option>
                                <option value="Skilled Persons Reviews">Skilled Persons Reviews</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                    </div>
                    <div class="control-group">
                        <label for="">Leave a Message?</label><br>
                        <textarea name="message" id="" cols="30" rows="10"
                            placeholder="Type Your Message Here..."></textarea>
                    </div>
                    <div class="control-group checkbox">
                        <input type="checkbox" id="checkboxed">
                        <label for="">I would like to receive invitations, insights and thought leadership content from ESHRA.</label>
                    </div>
                    <div class="control-group">
                        <button type="submit">Send Message</button>
                    </div>
                </form>
            </div>
            </div>
            </section>
</template>

<script>
export default {

}
</script>

<style>

</style>