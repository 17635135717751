<template>
      <section class="cooprate-culture-insights">
        <div class=" cooprate-culture-container gap-10 grid grid-cols-1 lg:grid-cols-2 px-5">
            <div class="cooprate-culture-insight" v-for="blog of blogs" :key="blog">
                <div class="top">
                    <a href="/article-detail"><span><i class="fa-solid fa-circle-chevron-right"></i></span><span>Compliance Risk</span></a>
                </div>
                <div class="bottom">
                    <div class="left">
                        <h2>Staying Ahead of the Curve: Understanding and Implementing the DOJ's Latest Guidelines</h2>
                        <p>Jul 10, 2023</p>
                        <p>By Wanabe Kimtuso</p>
                    </div>
                    <div class="right">
                        <div class="insights-image my-2">
                            <img src="/images/4.jpg" alt="eshra" class="h-full w-full rounded-lg">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return{
            blogs:[1,2,3,4]
        }
    }

}
</script>

<style>

</style>