
import router from '@/router'
import { signIn } from '@/services/auth'
import { getAllClients, getClientById, updateClient, deleteClient, listenClientsChanges,addClient, getAllAudits, getAuditId } from '@/services/firestore'
import swal from 'sweetalert'
import Vue from 'vue'
import Vuex from 'vuex'
const axios = require("axios").default;

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    clients:[
    ],
    user:{},
    loading:false,
    audits:[],
    singleAudit:{}
  },

  mutations: {
    setUser(state,val){
      state.user=val
    },
    setClients(state, clients) {
      state.clients = clients;
    },
    setLoading(state,val){
      state.loading=val
    },
    setAudits(state,val){
      state.audits=val
    },
    setSingleAudit(state,val){
      state.singleAudit=val
    }
  },
  actions: {
    login({commit},data){
      signIn(data).then((user)=>{
        console.log(user)
        commit('setUser',user)
        router.push('/dashboard')
      }).catch((err)=>{
        console.log(err)
      })
      
    },
    fetchClients({ commit }) {
      getAllClients().then(clients => {
        commit('setClients', clients.data);
      });
    },
    fetchClient(_, id) {
      return getClientById(id);
    },
    updateClient(_, { id, data }) {
      return updateClient(id, data);
    },
    deleteClient(_, id) {
      return deleteClient(id);
    },
    listenClientsChanges({ commit }) {
      return listenClientsChanges(clients => {
        commit('setClients', clients);
      });
    },
    addClient(_, data) {
      return addClient(data);
    },
    getAudits({commit}){
      return getAllAudits().then((audits)=>{
        commit('setAudits',audits.data)
      })
    },
    getSingleAudit({commit},id){
      getAuditId(id).then((data)=>{
        console.log("I received data",data)
        commit('setSingleAudit',data)
      })
    },
    
    async sendMail({ commit }, values) {
      commit('setLoading',true)
      await axios.post(
        "https://us-central1-scanpal-f74da.cloudfunctions.net/barizi/mail/eshra",
        {
          name: values.name,
          email: values.email,
          subject: values.subject,

          
          content: values.content,
          link:values.link
        }
      ).then(() => {
        swal({
          title: "Thank you",
          text: "Your anonymous reporting form has been submitted successfully",
          icon: "success",
        })
        commit('setLoading',false)
      })
    },
  },
  modules: {
  },
  getters: {

  }

})
