var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',[_c('div',{staticClass:"container mx-auto lg:px-32 py-4"},[_c('div',{staticClass:"lg:flex lg:items-center lg:justify-between"},[_c('div',{staticClass:"flex items-center justify-between"},[_vm._m(0),_c('div',{staticClass:"flex lg:hidden px-3 lg:p-0"},[_c('button',{staticClass:"z-30",attrs:{"x-cloak":"","type":"button","aria-label":"toggle menu"},on:{"click":_vm.toggleNav}},[(_vm.showMobilenav)?_c('svg',{staticClass:"w-6 h-6",attrs:{"x-show":"isOpen","xmlns":"http://www.w3.org/2000/svg","fill":"#13E2FF","viewBox":"0 0 24 24","stroke":"#13E2FF","stroke-width":"2"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M6 18L18 6M6 6l12 12"}})]):_c('svg',{staticClass:"w-6 h-6",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"#F5931D","viewBox":"0 0 24 24","stroke":"#F5931D","strokeWidth":"{2}"}},[_c('path',{attrs:{"strokeLinecap":"round","strokeLinejoin":"round","d":"M4 6h16M4 12h16M4 18h16"}})])])])]),_c('div',{class:[
  'nav-menu',
  'absolute',
  'inset-x-0',
  'z-20',
  'lg:w-full',
  'max-w-max', // Add this class for fit-content width
  'px-8',
  'pt-12',
  'pb-4',
  'transition-all',
  'duration-300',
  'ease-in-out',
  'lg:mt-0',
  'lg:p-0',
  'lg:top-0',
  'lg:relative',
  'lg:bg-transparent',
  'lg:w-auto',
  'lg:opacity-100',
  'lg:translate-x-0',
  'lg:flex',
  'lg:items-center',
  _vm.showMobilenav ? 'flex justify-end place-items-end' : 'hidden'
],attrs:{"x-cloak":""}},[_c('div',{staticClass:"flex flex-col -mx-6 lg:flex-row lg:items-center lg:mx-8"},[_c('a',{staticClass:"cursor-pointer px-3 py-2 mx-3 mt-2 transition-colors duration-300 transform rounded-md lg:mt-0 nav-links",attrs:{"href":"/"}},[_vm._v(" Home ")]),_c('a',{staticClass:"px-3 py-2 mx-3 mt-2 transition-colors duration-300 transform rounded-md lg:mt-0 nav-links",attrs:{"href":"/#about"}},[_vm._v(" About Us ")]),_c('a',{staticClass:"px-3 py-2 mx-3 mt-2 transition-colors duration-300 transform rounded-md lg:mt-0 nav-links whitespace-nowrap",attrs:{"href":"/#team"}},[_vm._v(" Our Team ")]),_c('a',{staticClass:"px-3 py-2 mx-3 mt-2 transition-colors duration-300 transform rounded-md lg:mt-0 nav-links whitespace-nowrap",attrs:{"href":"/#pricing"}},[_vm._v(" Pricing ")]),_c('a',{staticClass:"cursor-pointer px-3 py-2 mx-3 mt-2 transition-colors duration-300 transform rounded-md lg:mt-0 nav-links",attrs:{"exact":"","href":"/#faqs"}},[_vm._v(" FAQs ")]),_c('a',{staticClass:"px-10 py-2 mx-3 mt-2 transition-colors duration-300 transform lg:mt-0 bg-black text-white rounded-lg flex items-center justify-center gap-2",attrs:{"href":"#contact"}},[_vm._v(" Let's Chat "),_c('svg',{staticClass:"w-6 h-6",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke-width":"1.5","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 0 1-2.555-.337A5.972 5.972 0 0 1 5.41 20.97a5.969 5.969 0 0 1-.474-.065 4.48 4.48 0 0 0 .978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25Z"}})])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('a',{attrs:{"href":"/"}},[_c('img',{staticClass:"nav-logo",staticStyle:{"height":"62px"},attrs:{"src":"/images/logo.png"}})])])
}]

export { render, staticRenderFns }