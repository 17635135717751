<template>
  <div>
    <navbar-component></navbar-component>
    <div class="mx-auto w-full overflow-x-hidden">
      <div class="eshra-banner">
        <div class="eshra-mask"></div>
        <div class="eshra-text-container">
          <div class="cooprate-culture-container space-y-3">
            <h1>Monitorship Experts</h1>
            <p class="lg:max-w-[60%]">
              Trust ESHRA's experienced professionals for comprehensive
              corporate monitorship services. From oversight and compliance to
              integrity enhancement, our teams deliver invaluable guidance and
              detailed compliance reports, ensuring legal and ethical
              operations.
            </p>

              <a href="#contact" class="text-black btn-home bg-white rounded-lg px-10 py-2 flex items-center justify-center gap-2 w-[200px]">Contact Us <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
</svg>
</a>
          </div>
        </div>
        <video autoplay loop muted class="md:block hidden">
          <!-- Add your video source here -->
          <source
            src="\images\pexels-rodnae-productions-7841874 (1080p).mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
      <!-- <div
        class="h-[650px] bg-center bg-cover hero flex items-center justify-start px-5 lg:px-28"
      >
        <div class="space-y-2 max-1280-container">
          <h1 class="text-black">
            <span class="text-[#FCB833]">Monitorship Experts</span>
          </h1>

          <p class="lg:max-w-[60%]">Trust ESHRA's experienced professionals for comprehensive corporate monitorship services. From oversight and compliance to integrity enhancement, our teams deliver invaluable guidance and detailed compliance reports, ensuring legal and ethical operations.</p>
          <button class="text-white btn-home">
            <a href="#contact">Contact Us</a>
          </button>
        </div>
      </div> -->
      <section class="cooprate-culture-banner">
        <div class="cooprate-banner-mask">
          <div
            class="cooprate-culture-banner-container cooprate-culture-container"
          >
            <div class="banner-bottom">
              <div class="left px-5">
                <div class="top">
                  <a href="#">Our Services</a>
                </div>
                <p>
                  From monitorships to workplace misconduct investigations,
                  ESHRA offers unparalleled expertise. Our services include
                  corporate culture checks, sexual misconduct investigations,
                  and training and compliance program consulting. Trust us to
                  deliver thorough, fair, and independent solutions to mitigate
                  and remediate compliance and regulatory risks.
                </p>
                <button>Contact Us</button>
              </div>
              <div class="right px-5">
                <h2>Services we offer:</h2>
                <ul>
                  <li>
                    <a href="/mentorship"
                      ><span>MENTORSHIPS</span
                      ><i class="fa-solid fa-chevron-right"></i
                    ></a>
                  </li>
                  <li>
                    <a href="/corporate-culture-checks"
                      ><span>COORPORATE CULTURE CHECKS</span
                      ><i class="fa-solid fa-chevron-right"></i
                    ></a>
                  </li>
                  <li>
                    <a href="/sexual-misconduct"
                      ><span>SEXUAL MISCONDUCT INVESTIGATION</span
                      ><i class="fa-solid fa-chevron-right"></i
                    ></a>
                  </li>
                  <li>
                    <a href="/workplace-misconduct-investigation"
                      ><span>WORKPLACE MISCONDUCT INVESTIGATION</span
                      ><i class="fa-solid fa-chevron-right"></i
                    ></a>
                  </li>
                  <li>
                    <a href="/training-compliance"
                      ><span>TRAINING AND COMPLIANCE PROGRAM CONSULTING</span
                      ><i class="fa-solid fa-chevron-right"></i
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="cooprate-culture-container">
          <div
            class="grid grid-cols-1 md:grid-cols-2 px-5 py-20 accelerator gap-10"
            id="about"
          >
            
              <img src="/images/1.jpg" class="w-full object-cover  md:h-[500px] rounded-lg"/>
           
            <div class="space-y-2">

              <h2>Our Process</h2>
              
              <ol class="list-decimal list-inside text-black">
                <li class="border-b-2 border-b-solid border-black pb-5">
                  <h3>Assessment and Documentation</h3>
                  <p>
                    Thoroughly examining an organization's practices and
                    policies, collecting data and interviewing key personnel to
                    identify areas of concern.
                  </p>
                </li>
                <li class="border-b-2 border-b-solid border-black pb-5">
                  <h3>Compliance Analysis</h3>
                  <p>
                    Evaluate the organization's adherence to social, ethical,
                    and human rights standards, comparing their actions to
                    established guidelines, and documenting any violations.
                  </p>
                </li>
                <li class="border-b-2 border-b-solid border-black pb-5">
                  <h3>Reporting and Recommendations</h3>
                  <p>
                    Summarize findings in a comprehensive report, outlining
                    compliance status and providing actionable recommendations
                    for corrective actions and improvements.
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <blog-section></blog-section>
      <team-section></team-section>

      <section class="px-5 lg:px-20 py-20 bg-[#E6F5F5] criteria" id="pricing">
        <div class="cooprate-culture-container">
          <!-- <h1 class="text-center">
            Our <span class="text-[#FCB833]">Pricing</span>
          </h1>
          <p>
            Explore our pricing section, where simplicity meets value. We offer
            transparent pricing plans tailored to your needs, whether you're an
            individual or a business. Find the perfect solution to fit your
            budget and objectives.
          </p>
          <div
            class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 my-10 gap-6 md:gap-20"
          >
            <div class="bg-[#000000] grid-section p-4 space-y-4">
              <h3 class="text-white text-center">Bronze Package</h3>
              <p
                class="font-normal"
                style="color: #ffffff !important; font-weight: 300 !important"
              >
                We require that businesses have already launched, with a product
                or service in the market.
              </p>
              <hr class="h-1 bg-white w-full" />
              <h3 class="text-white font-bold text-center">KSH. 30,000</h3>
            </div>
            <div class="bg-[#FCB833] grid-section p-4 space-y-4">
              <h3 class="text-center">Silver Package</h3>
              <p class="font-normal">
                We require that businesses have already launched, with a product
                or service in the market.
              </p>
              <hr class="h-1 bg-black w-full" />
              <h3 class="text-white font-bold text-center">KSH. 30,000</h3>
            </div>
            <div class="bg-[#000000] grid-section p-4 space-y-4">
              <h3 class="text-white text-center">Gold Package</h3>
              <p
                class="font-normal"
                style="color: #ffffff !important; font-weight: 300 !important"
              >
                The product or service has the potential to be scaled through
                technology & digitization
              </p>
              <hr class="h-1 bg-white w-full" />
              <h3 class="text-white font-bold text-center">KSH. 30,000</h3>
            </div>
            <div class="bg-[#FCB833] grid-section p-4 space-y-4">
              <h3 class="text-center">Platinum Package</h3>
              <p class="font-normal">
                The core team has the capacity and expertise to drive overall
                growth.
              </p>
              <hr class="h-1 bg-white w-full" />
              <h3 class="text-white font-bold text-center">KSH. 30,000</h3>
            </div>
          </div> -->
          <h1 class="text-center " id="faqs">
            Frequently Asked <span class="text-[#FCB833]">Questions</span>
          </h1>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-10 my-6">
            <div class="space-y-10 text-left">
              <div class="flex justify-between items-center">
                <h3>What is Eshra</h3>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <mask
                    id="mask0_40_92"
                    style="mask-type: alpha"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                  >
                    <rect width="24" height="24" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_40_92)">
                    <path
                      d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                      fill="#121212"
                    />
                  </g>
                </svg>
              </div>
              <hr />
              <p class="text-left" style="text-align: left !important">
                A strong and diverse team is essential for the success of any
                SME. Look for teams with a mix of skills, experience, and
                personalities that complement each other.
              </p>
            </div>
            <div class="space-y-10 text-left">
              <div class="flex justify-between items-center">
                <h3>Can One report Corruption?</h3>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <mask
                    id="mask0_40_92"
                    style="mask-type: alpha"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                  >
                    <rect width="24" height="24" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_40_92)">
                    <path
                      d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                      fill="#121212"
                    />
                  </g>
                </svg>
              </div>
              <hr />
              <p class="text-left">
                The SME will have a solid financial foundation, including a
                clear understanding of their financials, revenue projections,
                and runway. The SME will have a solid plan for raising capital
                to fuel growth.
              </p>
            </div>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-10 mb-6">
            <div class="space-y-10 text-left">
              <div class="flex justify-between items-center">
                <h3>Will my details be shared?</h3>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <mask
                    id="mask0_40_92"
                    style="mask-type: alpha"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                  >
                    <rect width="24" height="24" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_40_92)">
                    <path
                      d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                      fill="#121212"
                    />
                  </g>
                </svg>
              </div>
              <hr />
              <p class="text-left">
                The SME will be operating in a growing or untapped market with a
                clear potential for growth. A deep understanding of the target
                market and customer needs is crucial.
              </p>
            </div>
            <div class="space-y-10 text-left">
              <div class="flex justify-between items-center">
                <h3>Scalability</h3>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <mask
                    id="mask0_40_92"
                    style="mask-type: alpha"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                  >
                    <rect width="24" height="24" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_40_92)">
                    <path
                      d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                      fill="#121212"
                    />
                  </g>
                </svg>
              </div>
              <hr />
              <p class="text-left">
                The SME will have a clear strategy for scaling the business,
                including a plan for customer acquisition, marketing, and
                distribution.
              </p>
            </div>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-10 mb-6">
            <div class="space-y-10 text-left">
              <div class="flex justify-between items-center">
                <h3>Where is Eshra head Offices?</h3>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <mask
                    id="mask0_40_92"
                    style="mask-type: alpha"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                  >
                    <rect width="24" height="24" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_40_92)">
                    <path
                      d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                      fill="#121212"
                    />
                  </g>
                </svg>
              </div>
              <hr />
              <p class="text-left">
                A clear and well-defined business model is necessary. The SME
                will have a clear revenue model, pricing strategy, and plan for
                scaling the business.
              </p>
            </div>
            <div class="space-y-10 text-left">
              <div class="flex justify-between items-center">
                <h3>Intellectual Property</h3>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <mask
                    id="mask0_40_92"
                    style="mask-type: alpha"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                  >
                    <rect width="24" height="24" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_40_92)">
                    <path
                      d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                      fill="#121212"
                    />
                  </g>
                </svg>
              </div>
              <hr />
              <p class="text-left">
                The SME will have a defensible intellectual property strategy in
                place, including patents, trademarks, and copyrights.
              </p>
            </div>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-10 mb-6">
            <div class="space-y-10 text-left">
              <div class="flex justify-between items-center">
                <h3>is Eshra Bellmac?</h3>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <mask
                    id="mask0_40_92"
                    style="mask-type: alpha"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                  >
                    <rect width="24" height="24" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_40_92)">
                    <path
                      d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                      fill="#121212"
                    />
                  </g>
                </svg>
              </div>
              <hr />
              <p class="text-left">
                The product or service will be tech based, innovative, and
                address a significant pain point for the target customers. The
                SME will have a clear roadmap for product development and
                improvement.
              </p>
            </div>
            <div class="space-y-10 text-left">
              <div class="flex justify-between items-center">
                <h3>How are investigations done?</h3>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <mask
                    id="mask0_40_92"
                    style="mask-type: alpha"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                  >
                    <rect width="24" height="24" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_40_92)">
                    <path
                      d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                      fill="#121212"
                    />
                  </g>
                </svg>
              </div>
              <hr />
              <p class="text-left">
                The SME will have a strong entrepreneurial mindset, with a
                willingness to take risks, embrace failure, and adapt to
                changing circumstances.
              </p>
            </div>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-10 mb-6">
            <div class="space-y-10 text-left">
              <div class="flex justify-between items-center">
                <h3>How much does it cost to onboard Eshra?</h3>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <mask
                    id="mask0_40_92"
                    style="mask-type: alpha"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                  >
                    <rect width="24" height="24" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_40_92)">
                    <path
                      d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                      fill="#121212"
                    />
                  </g>
                </svg>
              </div>
              <hr />
              <p class="text-left">
                The SME will have demonstrated some level of market validation,
                with a strong track record of customer acquisition, retention,
                and engagement.
              </p>
            </div>
            <div class="space-y-10 text-left">
              <div class="flex justify-between items-center">
                <h3>Which is your processe?</h3>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <mask
                    id="mask0_40_92"
                    style="mask-type: alpha"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                  >
                    <rect width="24" height="24" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_40_92)">
                    <path
                      d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                      fill="#121212"
                    />
                  </g>
                </svg>
              </div>
              <hr />
              <p class="text-left">
                The SME will have a positive social impact, either through their
                products or services, or through their broader social mission.
              </p>
            </div>
          </div>
        </div>
      </section>

      <form-component></form-component>
    </div>
    <footer></footer>
    <footer-component></footer-component>
  </div>
</template>

<script>
import NavbarComponent from "@/components/NavbarComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import { mapState } from "vuex";
import swal from "sweetalert";
import FormComponent from "@/components/utils/FormComponent.vue";
import BlogSection from "@/components/BlogSection.vue";
import TeamSection from "@/components/TeamSection.vue";

export default {
  data() {
    return {
      company: "",
      message: "",
      terms: false,
    };
  },
  computed: {
    ...mapState(["loading"]),
  },

  components: {
    NavbarComponent,
    FooterComponent,
    FormComponent,
    BlogSection,
    TeamSection,
  },
  methods: {
    aggreeToterms() {
      this.terms = !this.terms;
    },
    handleSubmit() {
      if (this.company && this.message) {
        this.$store.dispatch("sendMail", {
          name: "Admin",
          email: "mkiprop@bellmacconsulting.com",
          subject: "ESHRA Anonymous Report ",
          content: `company: ${this.company}, message:${this.message}`,
          link: `https://demo.eshra.org`,
        });
      } else {
        swal({
          text: "some fields are missing",
          icon: "error",
        });
      }
    },
  },
};
</script>

<style>
p.text-left {
  text-align: left !important;
}
</style>
