<template>
   <nav   
    >
      <div class="container mx-auto lg:px-32 py-4">
        <div class="lg:flex lg:items-center lg:justify-between">
          <div class="flex items-center justify-between">
            <div class="">
              <a href="/">
                <img src="/images/logo.png" class="nav-logo" style="height: 62px;"/>
              </a>
            </div>
            <div class="flex lg:hidden px-3 lg:p-0">
              <button
                x-cloak
                type="button"
                class="z-30"
                aria-label="toggle menu"
                @click="toggleNav"
              >
           
                  <svg
                    x-show="isOpen"
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-6 h-6"
                    fill="#13E2FF"                                              
                    viewBox="0 0 24 24"
                    stroke="#13E2FF"                                                 
                    stroke-width="2"
                    v-if="showMobilenav"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
 
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-6 h-6"
                    fill="#F5931D"
                    viewBox="0 0 24 24"
                    stroke="#F5931D"
                    strokeWidth={2}
                    v-else
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                
              </button>
            </div>
          </div>
          <div
            x-cloak
            :class="[
  'nav-menu',
  'absolute',
  'inset-x-0',
  'z-20',
  'lg:w-full',
  'max-w-max', // Add this class for fit-content width
  'px-8',
  'pt-12',
  'pb-4',
  'transition-all',
  'duration-300',
  'ease-in-out',
  'lg:mt-0',
  'lg:p-0',
  'lg:top-0',
  'lg:relative',
  'lg:bg-transparent',
  'lg:w-auto',
  'lg:opacity-100',
  'lg:translate-x-0',
  'lg:flex',
  'lg:items-center',
  showMobilenav ? 'flex justify-end place-items-end' : 'hidden'
]"

          
          >
            <div class="flex flex-col -mx-6 lg:flex-row lg:items-center lg:mx-8">

              <a
              
                href="/"
             
                class="cursor-pointer px-3 py-2 mx-3 mt-2 transition-colors duration-300 transform rounded-md lg:mt-0  nav-links"
                
              >
                Home
            </a>

              <a
            href="/#about"
                class="px-3 py-2 mx-3 mt-2 transition-colors duration-300 transform rounded-md lg:mt-0  nav-links"
               
              >
                About Us
          </a>

              <a
               href="/#team"
                class="px-3 py-2 mx-3 mt-2 transition-colors duration-300 transform rounded-md lg:mt-0  nav-links whitespace-nowrap"
              
              >
                Our Team
        </a>

              <a
              href="/#pricing"
                class="px-3 py-2 mx-3 mt-2 transition-colors duration-300 transform rounded-md lg:mt-0  nav-links whitespace-nowrap"
               
              >
                Pricing
      </a>
              
              <a
              exact
              href="/#faqs"
                class="cursor-pointer px-3 py-2 mx-3 mt-2 transition-colors duration-300 transform rounded-md lg:mt-0  nav-links"
               
              >
                FAQs
    </a>
    

              <a
                href="#contact"
                class="px-10 py-2 mx-3 mt-2 transition-colors duration-300 transform lg:mt-0 bg-black text-white rounded-lg flex items-center justify-center gap-2"
              > 
                Let's Chat <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 0 1-2.555-.337A5.972 5.972 0 0 1 5.41 20.97a5.969 5.969 0 0 1-.474-.065 4.48 4.48 0 0 0 .978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25Z" />
</svg>

              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </template>
  
  <script>
export default {
  data() {
    return {
      showMobilenav: false,
    };
  },
  methods: {
    toggleNav() {
      this.showMobilenav = !this.showMobilenav;
      console.log(this.showMobilenav);
    },
  },
};
</script>
  
  <style>
</style>