<template>
      <section class="cooprate-culture-profile-cards" id="team">
        <!-- <div class="cooprate-culture-profile-cards-container cooprate-culture-container">
            <div class="profile-card">
                <div class="profile-image">
                    <img src="/images/anne.jpg" alt="eshra">
                </div>
                <div class="info-profile">
                    <h3>Ann Otunga</h3>
                    <p>Managing Director and Global Head of Compliance Risk and Diligence</p>
                    <div class="socials">
                        <a href="#"><i class="fa-brands fa-facebook"></i></a>
                        <a href="#"><i class="fa-brands fa-twitter"></i></a>
                        <a href="#"><i class="fa-brands fa-linkedin"></i></a>
                    </div>
                </div>
            </div>
            <div class="profile-card">
                <div class="profile-image">
                    <img src="https://firebasestorage.googleapis.com/v0/b/scanpal-f74da.appspot.com/o/files%2Fb76w82abishh22keMO8vHfJgB713%2FMicrosoftTeams-image-4~2.png.image%2Fpng?alt=media&token=1374f7a8-5b6d-4352-ab93-8c92bdded620" alt="eshra">
                </div>
                <div class="info-profile">
                    <h3>Stella Orengo</h3>
                    <p>Managing Director and Global Head of Compliance Risk and Diligence</p>
                
                    <div class="socials">
                        <a href="#"><i class="fa-brands fa-facebook"></i></a>
                        <a href="#"><i class="fa-brands fa-twitter"></i></a>
                        <a href="#"><i class="fa-brands fa-linkedin"></i></a>
                    </div>
                </div>
            </div>
            <div class="profile-card">
                <div class="profile-image">
                    <img src="/images/maureen.jpg" alt="eshra">
                </div>
                <div class="info-profile">
                    <h3>Maureen Kiprop</h3>
                    <p>Managing Director and Global Head of Compliance Risk and Diligence</p>
                  
                    <div class="socials">
                        <a href="#"><i class="fa-brands fa-facebook"></i></a>
                        <a href="#"><i class="fa-brands fa-twitter"></i></a>
                        <a href="#"><i class="fa-brands fa-linkedin"></i></a>
                    </div>
                </div>
            </div>
            <div class="profile-card">
                <div class="profile-image">
                    <img src="/images/lilian.png" alt="eshra">
                </div>
                <div class="info-profile">
                    <h3>Lilian Ambani</h3>
                    <p>Managing Director and Global Head of Compliance Risk and Diligence</p>
                    
                    <div class="socials">
                        <a href="#"><i class="fa-brands fa-facebook"></i></a>
                        <a href="#"><i class="fa-brands fa-twitter"></i></a>
                        <a href="#"><i class="fa-brands fa-linkedin"></i></a>
                    </div>
                </div>
            </div>

        </div> -->
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>