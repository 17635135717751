import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { auth } from '@/services';


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/SignView.vue')
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import(/* webpackChunkName: "about" */ '../views/ForgotPasswordView.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "about" */ '../views/DashboardView.vue')
  },
  {
    path: '/clients',
    name: 'Clients',
    component: () => import(/* webpackChunkName: "about" */ '../views/ClientsView.vue')
  },
  {
    path: '/audits',
    name: 'Audits',
    component: () => import(/* webpackChunkName: "about" */ '../views/BusinessAuditsView.vue')
  },
  {
    path: '/respond/:id',
    name: 'Respond',
    component: () => import(/* webpackChunkName: "response" */ '../views/ResponseForm.vue')
  },
  {
    path: '/blank-form',
    name: 'BlankForm',
    component: () => import(/* webpackChunkName: "blankform" */ '../views/BlankFormView.vue')
  },
  {
    path: '/form/:id',
    name: 'SingleForm',
    component: () => import(/* webpackChunkName: "single form" */ '../views/SingleForm.vue')
  },
  {
    path: '/audit/:id',
    name: 'SingleAudit',
    component: () => import(/* webpackChunkName: "singleaudit" */ '../views/SingleAuditVIew.vue')
  },
  {
    path: '/forms',
    name: 'Forms',
    component: () => import(/* webpackChunkName: "allforms" */ '../views/AlllFormsView.vue')
  },
  {
    path: '/new-client',
    name: 'NewClient',
    component: () => import(/* webpackChunkName: "newclient" */ '../views/NewClientView.vue')
  },
  {
    path: '/mentorship',
    name: 'MentorshipView',
    component: () => import(/* webpackChunkName: "newclient" */ '../views/MentorshipView.vue')
  },
  {
    path: '/sexual-misconduct',
    name: 'SexualMisconductInvestigationView',
    component: () => import(/* webpackChunkName: "newclient" */ '../views/SexualMisconductInvestigationView.vue')
  },
  {
    path: '/workplace-misconduct-investigation',
    name: 'WorkplaceMisconductInvestigationView',
    component: () => import(/* webpackChunkName: "newclient" */ '../views/WorkplaceMisconductInvestigationView.vue')
  },
  {
    path: '/training-compliance',
    name: 'TrainingComplianceView',
    component: () => import(/* webpackChunkName: "newclient" */ '../views/TrainingComplianceView.vue')
  },
  {
    path: '/corporate-culture-checks',
    name: 'CooprateCulturechecks',
    component: () => import(/* webpackChunkName: "newclient" */ '../views/CooprateCulturechecksView.vue')
  },
  {
    path: '/mailing',
    name: 'Mailing',
    component: () => import(/* webpackChunkName: "mailing group" */ '../views/MailingGroups.vue')
  },
  {
    path: '/workplace-misconduct',
    name: 'WorkplaceMisconduct',
    component: () => import(/* webpackChunkName: "workplace misconduct" */ '../views/ChildTemplatePage.vue')
  },
  {
    path: '/article-detail',
    name: 'ArticleDetail',
    component: () => import(/* webpackChunkName: "workplace misconduct" */ '../views/ArticleDetailView.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const user = auth.currentUser;

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!user) {
      next({
        path: '/',
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router
