import { firestoreDb } from "@/services";

import {
  addDoc,
  collection,
  deleteDoc,
  deleteField,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  updateDoc,
  setDoc,
  arrayUnion
} from "firebase/firestore";

// const USERS_PATH = 'users'
const CLIENTS_PATH = "clients";
const AUDITS_PATH = 'audits'
// const GENERALFEEDBACK_PATH='generalfeedback'

export const firestoreListener = onSnapshot;
export const deleteDbField = deleteField();

export const formatQueryDataArray = (querySnapshot) => {
    let result = [];
    querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id; // add the document id to the data
        result.push(data);
    });
    return result;
}
export const formatQueryDataObject = (doc) => {
    let data = null;
    if (doc.exists()) {
        data = doc.data();
        data.id = doc.id; // add the document id to the data
    }
    return data;
}

const getDocuments = (query) => {
  return getDocs(query).then((docs) => {
    return { data: formatQueryDataArray(docs), docs: docs.docs };
  });
};



const getDocument = (ref) => {
  return getDoc(ref).then((doc) => formatQueryDataObject(doc));
};
const addDocument = (ref, data) => {
  return addDoc(ref, data);
};

const setDocument = (path, docId, data) => {
	return setDoc(doc(firestoreDb, path, docId), data)
}
export const addClient = (data) => {
  const ref = collection(firestoreDb, CLIENTS_PATH);
  return addDocument(ref, data);
};

const updateDocument = (ref, data) => {
  return updateDoc(ref, data);
};

const deleteDocument = (ref, docId) => {
  return deleteDoc(doc(firestoreDb, ref, docId));
};
//CLIENTS
export const getAllClients = () => {
  const q = query(collection(firestoreDb, CLIENTS_PATH));
  return getDocuments(q);
};

export const getClientById = (id) => {
  const docRef = doc(firestoreDb, CLIENTS_PATH, id);
  return getDocument(docRef);
};

export const updateClient = (id, data) => {
  const docRef = doc(firestoreDb, CLIENTS_PATH, id);
  return updateDocument(docRef, data);
};

export const deleteClient = (id) => {
  const docRef = doc(firestoreDb, CLIENTS_PATH, id);
  return deleteDocument(docRef);
};

export const listenClientsChanges = (callback) => {
  const q = query(collection(firestoreDb, CLIENTS_PATH));
  return onSnapshot(q, (snapshot) => {
    let data = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    callback(data);
  });
};

//audits 
export const addAudit = (docId,data) => {
  return setDocument(AUDITS_PATH, docId, data) 

};

export const getAllAudits = () => {
  const q = query(collection(firestoreDb, AUDITS_PATH));
  return getDocuments(q);
};

export const getAuditId = (id) => {
  const docRef = doc(firestoreDb, AUDITS_PATH, id);
  return getDocument(docRef);
};
export const updateAudit = (id, data) => {
  const docRef = doc(firestoreDb, AUDITS_PATH, id);
  return updateDocument(docRef, {
    responses:arrayUnion(data)
  });
};